const track = document.querySelector(".carousel__track")
const slides = Array.from(track.children)
const nextButton = document.querySelector(".carousel__arrows__container--right");
const prevButton = document.querySelector(".carousel__arrows__container--left");
// const nextButton = document.querySelector(".carousel__button--right");
// const prevButton = document.querySelector(".carousel__button--left");
const dotsNav = document.querySelector(".carousel__nav")
const dots = Array.from(dotsNav.children);

const slideWidth = slides[0].getBoundingClientRect().width;

const setSlidePosition = (slide, index) => {
	slide.style.left = slideWidth * index + "px";
}

slides.forEach(setSlidePosition)

const moveSlide = (track, currentSlide, targetSlide) => {
	const lenghtToMove = targetSlide.style.left;
	track.style.transform = "translateX(-" + lenghtToMove + ")";

	currentSlide.classList.remove("current-slide");
	targetSlide.classList.add("current-slide");
}

const updateDots = (currentDot, targetDot) => {
	currentDot.classList.remove("current-slide");
	targetDot.classList.add("current-slide");
}

const updateArrows = (slides, nextButton, prevButton, targetIndex) => {
	if (0 == targetIndex) {
		prevButton.classList.add("hide");
		nextButton.classList.remove("hide");
	} else if ((slides.length - 1) == targetIndex) {
		prevButton.classList.remove("hide");
		nextButton.classList.add("hide");
	} else {
		prevButton.classList.remove("hide");
		nextButton.classList.remove("hide");
	}
}

nextButton.addEventListener("click", e => {
	const currentSlide = track.querySelector(".current-slide");
	const nextSlide = currentSlide.nextElementSibling;

	moveSlide(track, currentSlide, nextSlide);

	const currentDot = dotsNav.querySelector(".current-slide");
	const nextDot = currentDot.nextElementSibling;

	updateDots(currentDot, nextDot);

	const targetIndex = slides.findIndex(slide => nextSlide === slide);

	updateArrows(slides, nextButton, prevButton, targetIndex);
})

prevButton.addEventListener("click", e => {
	const currentSlide = track.querySelector(".current-slide");
	const prevSlide = currentSlide.previousElementSibling;

	moveSlide(track, currentSlide, prevSlide);

	const currentDot = dotsNav.querySelector(".current-slide");
	const prevDot = currentDot.previousElementSibling;

	updateDots(currentDot, prevDot);

	const targetIndex = slides.findIndex(slide => prevSlide === slide);

	updateArrows(slides, nextButton, prevButton, targetIndex);
})

dotsNav.addEventListener("click", e => {
	const targetDot = e.target.closest("div.carousel__nav-dot");

	if(!targetDot) return;

	const currentSlide = track.querySelector(".current-slide");
	const currentDot = dotsNav.querySelector(".current-slide");

	const targetIndex = dots.findIndex(dot => dot === targetDot);
	const targetSlide = slides[targetIndex];

	moveSlide(track, currentSlide, targetSlide);


	updateDots(currentDot, targetDot);
	updateArrows(slides, nextButton, prevButton, targetIndex);
})