// Delay the home more arrow from appearing on load.
document.querySelector(".home__more").style.opacity = 1;

// Close the hamburger when a link is clicked
const nav_title = document.querySelector('.header__nav-title');
const nav_list = document.querySelector('.header__nav__list');
const checkbox = document.getElementById("header__hamburger-check");

nav_title.addEventListener('click', handleMenuClick);
nav_list.addEventListener('click', handleMenuClick);

function handleMenuClick(event) {
  if (event.target instanceof HTMLAnchorElement) {
    checkbox.checked = false;
  }
}